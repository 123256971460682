<template>
    <b-card no-body class="transparent">
        <b-card-body>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="3">
                            <v-btn color="primary" @click="showLogoUploadDialog = true"><v-icon
                                    style="margin-right: 10px;">backup</v-icon> {{ $t('generic.lang_uploadLogo')
                                }}
                            </v-btn>
                            <v-btn :loading="deleteLogoLoading" :disabled="deleteLogoLoading" @click="deleteLogo"
                                v-if="logo" color="error"><v-icon style="margin-right: 10px;">delete</v-icon> {{
                                    $t('generic.lang_removeLogo')
                                }}
                            </v-btn>
                        </v-col>
                        <v-col v-if="logo && logo.length > 0" cols="9" class="justify-center">
                            <v-img height="100" contain :src="logoUrl"></v-img>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-card :disabled="loading" :loading="loading" elevation="0">
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group v-model="templateType" mandatory :label="$t('generic.lang_mode')"
                                            row>
                                            <v-radio :value="0" :label="$t('generic.lang_simple')"></v-radio>
                                            <v-radio :value="1" :label="$t('generic.lang_youtubePlayer')"></v-radio>
                                            <v-radio :value="2" :label="$t('generic.lang_slideShow')"></v-radio>
                                        </v-radio-group>
                                    </v-col>

                                    <v-col v-if="templateType === 1" cols="12">
                                        <v-text-field :label="$t('generic.lang_youtubeVideoURL')"
                                            placeholder="https://www.youtube.com/watch?v=wJhAbFSiANU"
                                            v-model="youtubeURL" outlined></v-text-field>
                                    </v-col>

                                    <v-col v-if="templateType === 2" cols="12">
                                        <v-btn color="primary" @click="showImageUploadDialog = true"> {{
                                            $t('generic.lang_uploadImage') }} </v-btn>

                                        <v-container fluid>
                                            <v-row>
                                                <v-col v-for="image in slideshow_images" :key="image"
                                                    class="d-flex child-flex" cols="4">
                                                    <TemplateSlideshowImage @imageDelete="imageDeleted" :image="image">
                                                    </TemplateSlideshowImage>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success" :loading="loading" :disabled="loading" @click="saveTemplate"> {{
                                $t('generic.lang_save') }} </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <TemplateLogoUploadDialog :show-dialog="showLogoUploadDialog" @imageUploaded="logoUploaded"
                    @closeDialog="showLogoUploadDialog = false"></TemplateLogoUploadDialog>
                <TemplateImageUploadDialog :show-dialog="showImageUploadDialog" @imageUploaded="imageUploaded"
                    @closeDialog="showImageUploadDialog = false"></TemplateImageUploadDialog>
            </v-row>
        </b-card-body>
    </b-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import TemplateSlideshowImage from "./TemplateSlideshowImage";
import TemplateImageUploadDialog from "./TemplateImageUploadDialog";
import TemplateLogoUploadDialog from "./TemplateLogoUploadDialog";
import { mapState } from "vuex";
import {Events} from "@/plugins/events";
export default {
    name: "Template",

    components: {
        TemplateLogoUploadDialog,
        TemplateImageUploadDialog,
        TemplateSlideshowImage
    },

    data() {
        return {
            loading: false,
            deleteLogoLoading: false,
            templateType: 0,
            logo:null,
            youtubeURL:null,
            slideshow_images: [],
            showLogoUploadDialog: false,
            showImageUploadDialog: false,
            loadingLogoUpload: false,
            loadingImageUpload: false,
        }
    },

    mounted() {
        this.getTemplate();
    },

    computed: {
        ...mapState([
            'api'
        ]),
        logoUrl() {
            if (this.logo && this.logo.includes('data:image/'))
                return this.logo;

            if (this.logo && this.logo?.length > 0)
                return "https://img.3pos.de/unsafe/billsys_" + this.api.auth.posID + "/original/" + this.logo + ".png";

            return null
        }
    },

    methods: {
        getTemplate() {
            this.loading = true;

            this.axios.post(ENDPOINTS.SETTINGS.PICKUPMONITOR.SHOWCASEDISPLAY.GET)
            .then((res) => {
                if(res.data.success) {
                    this.logo = res.data.template.pickUpMonitorLogoName;
                    this.youtubeURL = res.data.template.youtubePlayerUrl;
                    this.templateType = res.data.template.youtubePlayer? 1 : (res.data.template.slideshow? 2 : 0);
                    this.slideshow_images = res.data.template.slideshow_images || [];
                }else {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred"),
                        color: "error",
                    });
                }
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred") + ': ' + err,
                        color: "error",
                    });
            }).finally(() => {
                this.loading = false;
            });
        },
        saveTemplate() {
            this.loading = true;

            this.axios.post(ENDPOINTS.SETTINGS.PICKUPMONITOR.SHOWCASEDISPLAY.UPDATE, {
                youtubeURL: this.youtubeURL,
                templateType: this.templateType,
            }).then((res) => {
                if (res.data.success) {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.generic.lang_dataUpdated"),
                        color: "success",
                    });  
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred"),
                        color: "error",
                    });
                }
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred") + ': ' + err,
                    color: "error",
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        logoUploaded() {
            this.showLogoUploadDialog = false;
            this.getTemplate();
        },
        imageUploaded() {
            this.showImageUploadDialog = false;
            this.getTemplate();
        },
        imageDeleted() {
            this.getTemplate();
        },
        deleteLogo() {
            this.loadingLogoUpload = true;

            this.axios.post(ENDPOINTS.SETTINGS.PICKUPMONITOR.SHOWCASEDISPLAY.LOGO.DELETE, {
            }).then((res) => {
                if (res.data.success) {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_deletedSuccessfully"),
                        color: "success",
                    });  
                    this.getTemplate();
                }else {
                    Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred"),
                    color: "error",
                });
                }

                this.loadingLogoUpload = false;
            }).catch(() => {
                this.loadingLogoUpload = false;
                Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred") + ': ' + err,
                    color: "error",
                });
            });
        }
    }
}
</script>