<template>
    <v-card flat tile>
        <v-img :src="imageUrl" aspect-ratio="1" class="grey lighten-2">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
            </template>
        </v-img>

        <v-card-actions>
            <v-btn @click="deleteImage" :loading="deleteLoading" :disabled="deleteLoading" color="error" text>
                <v-icon>delete</v-icon> {{ $t('generic.lang_delete') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import { ENDPOINTS } from "@/config";
import {Events} from "@/plugins/events";
export default {
    name: "TemplateSlideshowImage",

    props: {
        image: String,
    },

    computed: {
        ...mapState([
            'api'
        ]),
        imageUrl() {
            return "https://img.3pos.de/unsafe/250x250/billsys_" + this.api.auth.posID + "/original/" + this.image + ".png";
        }
    },

    data() {
        return {
            deleteLoading: false
        }
    },

    methods: {
        deleteImage() {
            this.deleteLoading = true;

            this.axios.post(ENDPOINTS.SETTINGS.PICKUPMONITOR.SHOWCASEDISPLAY.SLIDESHOWIMAGE.DELETE, {
                imageUID: this.image
            }).then((res) => {
                if (res.data.success) {
                    this.$emit("imageDelete");
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_deletedSuccessfully"),
                        color: "success",
                    });
                }else {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred"),
                        color: "error",
                    });
                }

                this.deleteLoading = false;
            }).catch(() => {
                this.deleteLoading = false;
                Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred"),
                        color: "error",
                    });
            });
        }
    }
}
</script>