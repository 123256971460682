var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","sm":"12","md":"3"}},[_c('b-card',{staticClass:"mb-1",class:[this.$vuetify.theme.dark ? 'dark-bg' : ''],attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('v-list-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.global-settings",modifiers:{"global-settings":true}}],staticClass:"ma-0",on:{"click":function($event){_vm.opened = !_vm.opened}}},[_c('v-list-item-title',{class:[this.$vuetify.theme.dark ? '' : '', this.opened ? 'primary--text text-wrap' : 'text-wrap']},[_vm._v(" "+_vm._s(this.$t('settings.lang_pickupMonitorInterface'))+" ")]),_c('v-list-item-action',[(!this.opened)?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_vm._e(),(this.opened)?_c('v-icon',[_vm._v("keyboard_arrow_up")]):_vm._e()],1)],1)],1),_c('b-collapse',{attrs:{"visible":this.opened,"accordion":"global-settings","id":"global-settings","role":"tabpanel"}},[_c('b-card-body',{staticClass:"pa-0"},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"items",class:[
                this.$vuetify.theme.dark ? 'dark-bg' : '',
                this.currentTab === 'general_settings'
                  ? 'primary--text'
                  : '',
              ],on:{"click":function($event){return _vm.handleClick('general_settings')}}},[_vm._v(" "+_vm._s(this.$t("generic.lang_globalSettings"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                this.$vuetify.theme.dark ? 'dark-bg' : '',
                this.currentTab === 'colors' ? 'primary--text' : '',
              ],on:{"click":function($event){return _vm.handleClick('colors')}}},[_vm._v(" "+_vm._s(this.$t("tablebee.lang_theme_colors"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                this.$vuetify.theme.dark ? 'dark-bg' : '',
                this.currentTab === 'text_colors' ? 'primary--text' : '',
              ],on:{"click":function($event){return _vm.handleClick('text_colors')}}},[_vm._v(" "+_vm._s(this.$t("tablebee.lang_text_settings"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                this.$vuetify.theme.dark ? 'dark-bg' : '',
                this.currentTab === 'slideshow' ? 'primary--text' : '',
              ],on:{"click":function($event){return _vm.handleClick('slideshow')}}},[_vm._v(" "+_vm._s(this.$t("generic.lang_showcaseDisplay"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                this.$vuetify.theme.dark ? 'dark-bg' : '',
                this.currentTab === 'messages' ? 'primary--text' : '',
              ],on:{"click":function($event){return _vm.handleClick('messages')}}},[_vm._v(" "+_vm._s(this.$t("generic.lang_gui_messages"))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                this.$vuetify.theme.dark ? 'dark-bg' : '',
                this.currentTab === 'help' ? 'primary--text' : '',
              ],on:{"click":function($event){return _vm.handleClick('help')}}},[_vm._v(" "+_vm._s(this.$t("Help"))+" ")])],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"12","sm":"12","md":"9"}},[_c('v-card',[_c('div',{attrs:{"role":"tablist"}},[(_vm.currentTab === 'general_settings')?_c('div',[_c('GeneralSettings')],1):_vm._e(),(_vm.currentTab === 'colors')?_c('div',[_c('ThemeColors')],1):_vm._e(),(_vm.currentTab === 'text_colors')?_c('div',[_c('TextSettings')],1):_vm._e(),(_vm.currentTab === 'slideshow')?_c('div',[_c('ShowcaseDisplay')],1):_vm._e(),(_vm.currentTab === 'messages')?_c('div',[_c('Messages')],1):_vm._e()])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }