<template>
    <v-dialog persistent :value="showDialog" max-width="700">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title> {{ $t('generic.lang_uploadLogo') }} </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog" :disabled="loading">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <Imagecropper v-if="showDialog" ref="cropper" v-model="image" :aspectRatio="null"
                    :containerStyle="{ 'max-height': '400px' }"></Imagecropper>
            </v-card-text>

            <v-card-actions>
                <v-btn text color="error" @click="closeDialog" :disabled="loading"> {{ $t('generic.lang_cancel') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="uploadImage" :loading="loading" :disabled="loading"> {{
                    $t('generic.lang_hochladen') }} </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Imagecropper from "@/components/common/imagecropper";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
export default {
    name: "TemplateLogoUploadDialog",

    components: {
        Imagecropper
    },

    props: {
        showDialog: Boolean
    },

    data() {
        return {
            image: null,
            loading: false
        }
    },

    watch: {
        showDialog(val) {
            if (!val) {
                this.image = null;
            }
        }
    },

    methods: {
        closeDialog() {
            this.$emit("closeDialog");
        },
        uploadImage() {
            this.loading = true;

            this.$refs.cropper.cropImage();

            this.$nextTick().then(() => {
                this.axios.post(ENDPOINTS.SETTINGS.PICKUPMONITOR.SHOWCASEDISPLAY.LOGO.UPDATE, {
                    uploadImage: this.image
                }).then((res) => {
                    if (res.data.success) {
                        this.$emit("imageUploaded");
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_errorOccurred"),
                            color: "error",
                        });
                    }

                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred"),
                        color: "error",
                    });
                });
            });
        }
    }
}
</script>